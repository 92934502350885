define("discourse/plugins/discourse-whos-online/discourse/services/whos-online", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/models/site"], function (_exports, _object, _computed, _service, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let WhosOnlineService = _exports.default = (_dec = (0, _computed.readOnly)("channel.users"), _dec2 = (0, _computed.readOnly)("channel.count"), _dec3 = (0, _computed.readOnly)("channel.countOnly"), (_class = class WhosOnlineService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "presence", _descriptor, this);
      _initializerDefineProperty(this, "appEvents", _descriptor2, this);
      _initializerDefineProperty(this, "users", _descriptor3, this);
      _initializerDefineProperty(this, "count", _descriptor4, this);
      _initializerDefineProperty(this, "countOnly", _descriptor5, this);
    }
    init() {
      super.init(...arguments);
      this.set("channel", this.presence.getChannel("/whos-online/online"));
      if (this.enabled) {
        this.channel.subscribe(_site.default.currentProp("whos_online_state"));
      }
      this.addObserver("users.[]", this, this._usersChanged);
    }
    _usersChanged() {
      const currentUserIds = new Set(this.users?.map(u => u.id) || []);
      const prevUserIds = this._prevUserIds || new Set([]);
      const enteredUsers = [...currentUserIds].filter(x => !prevUserIds.has(x));
      const leftUsers = [...prevUserIds].filter(x => !currentUserIds.has(x));
      const changedUsers = [...enteredUsers, ...leftUsers];
      if (changedUsers.length > 0) {
        this.appEvents.trigger("whosonline:changed", changedUsers);
      }
    }
    get enabled() {
      const anonAndLoginRequired = !this.currentUser && this.siteSettings.login_required;
      if (anonAndLoginRequired) {
        return false;
      }
      return this.siteSettings.whos_online_display_public || this.currentUser?.trust_level >= this.siteSettings.whos_online_display_min_trust_level;
    }
    isUserOnline(id) {
      return !!this.channel?.users?.findBy("id", id);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "presence", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "count", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "countOnly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "enabled", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "enabled"), _class.prototype)), _class));
});