define("discourse/plugins/discourse-whos-online/discourse/components/whos-online-avatar", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class WhosOnlineAvatar extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tagName", "a");
      _defineProperty(this, "attributeBindings", ["user.username:data-user-card", "user.path:href"]);
    }
  }
  _exports.default = WhosOnlineAvatar;
});